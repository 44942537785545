import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid, { GridItem } from '@marvelapp/marvel-ui/grid';

import Project from './Project';

class ProjectGrid extends Component {
  getPlayerUrl(url) {
    return `/player/${url.split('/')[4]}/personal/`;
  }

  getProjectThumbnailUrl(projectContent) {
    console.log('Project content is', projectContent);
    let question = '';
    if (projectContent.length > 0) {
      if (
        projectContent[0].node.content &&
        projectContent[0].node.content.url
      ) {
        const url = projectContent[0].node.content.url;
        if (url.indexOf('?') < 0) {
          question = '?';
        }
        return `${url}${question}&width=320&quality=90&height=320&crop=320:320,offset-y0&fake=.png`;
      }
    }
    return null;
  }

  render() {
    const projects = this.props.projects;
    console.log('The project props are ', projects);
    return (
      <Grid columns={4} size="l">
        {projects.map((project) => (
          <GridItem
            columnSpan={4}
            columnSpanM={1}
            columnSpanS={2}
            key={project.node.name}
          >
            <Project
              frame={project.node.settings.deviceFrame}
              image={this.getProjectThumbnailUrl(project.node.screens.edges)}
              modifiedAt={project.node.modifiedAt}
              name={project.node.name}
              url={this.getPlayerUrl(project.node.prototypeUrl)}
              prototypeUrl={project.node.prototypeUrl}
              tab={project.node.tab}
            />
          </GridItem>
        ))}
      </Grid>
    );
  }
}

ProjectGrid.propTypes = {
  projects: PropTypes.array,
  tabList: PropTypes.array,
};

export default ProjectGrid;
