import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

import AspectRatio from '@marvelapp/marvel-ui/aspect-ratio';
import Button from '@marvelapp/marvel-ui/button';
import Heading from '@marvelapp/marvel-ui/heading';
import Paragraph from '@marvelapp/marvel-ui/paragraph';
import Position from '@marvelapp/marvel-ui/position';
import Spacing from '@marvelapp/marvel-ui/spacing';
import Card, {
  CardAction,
  CardActions,
  CardBody,
  CardFooter,
  CardThumbnail,
} from '@marvelapp/marvel-ui/card';

const OFFSET = 78;

class Project extends Component {
  constructor() {
    super();
    this.state = { isHovered: false };
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  copyToClipboard() {
    const text = this.props.prototypeUrl;
    console.log('Text is ', text);
    if (window.clipboardData && window.clipboardData.setData) {
      // IE specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData('Text', text);
    } else if (
      document.queryCommandSupported &&
      document.queryCommandSupported('copy')
    ) {
      var textarea = document.createElement('textarea');
      textarea.textContent = text;
      textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand('copy'); // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn('Copy to clipboard failed.', ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }

  mouseOver() {
    this.setState({ isHovered: true });
  }

  mouseOut() {
    this.setState({ isHovered: this.state.isDropdownOpen });
  }

  render() {
    const { image, name, frame, modifiedAt, tab } = this.props;
    let url = this.props.url;
    const { isHovered } = this.state;
    let subInfo = (
      <Paragraph size="s" color="slate">
        Last updated on <Moment format="Do MMMM YYYY">{modifiedAt}</Moment>
      </Paragraph>
    );
    let viewButtonText = 'Project';
    let goToUrl = () => {
      window.location.href = url;
    };
    if (tab) {
      goToUrl = () => {
        window.microsoftTeams.navigateToTab(tab);
      };
      viewButtonText = 'Tab';
      subInfo = (
        <Paragraph size="s" color="slate">
          in {tab.channelName} / {tab.teamName}
        </Paragraph>
      );
    }
    return (
      <div onMouseEnter={this.mouseOver} onMouseLeave={this.mouseOut}>
        <Card type="project">
          <AspectRatio ratio="1-1">
            <CardThumbnail frame={frame} offset={OFFSET} thumbnail={image} />

            <CardBody isHovered={isHovered}>
              <Position type="absolute" fullWidth top={0} bottom={OFFSET}>
                <Position type="absolute" location="center">
                  <a onClick={goToUrl}>
                    <Button hover="green" kind="ghost" type="button" isReversed>
                      View {viewButtonText}
                    </Button>
                  </a>
                </Position>
              </Position>
              <CardActions>
                <a onClick={this.copyToClipboard}>
                  <CardAction>Copy Link</CardAction>
                </a>
              </CardActions>
            </CardBody>

            <CardFooter>
              <Spacing type="padding" position="bottom" size="s">
                <Spacing type="margin" position="bottom" size="xxs">
                  <Heading weight="semibold">{name}</Heading>
                </Spacing>
                {subInfo}
              </Spacing>
            </CardFooter>
          </AspectRatio>
        </Card>
      </div>
    );
  }
}

Project.propTypes = {
  frame: PropTypes.string,
  image: PropTypes.string,
  modifiedAt: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
};

export default Project;
