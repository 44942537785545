import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';

import { InlineGrid } from '@marvelapp/marvel-ui/grid';
import Paragraph from '@marvelapp/marvel-ui/paragraph';
import Align from '@marvelapp/marvel-ui/align';
import Link from '@marvelapp/marvel-ui/link';
import Loader from '@marvelapp/marvel-ui/loader';
import PageWrap from '@marvelapp/marvel-ui/page-wrap';
import Spacing from '@marvelapp/marvel-ui/spacing';
import MessageBlock from '@marvelapp/marvel-ui/message-block';

import favicon from '../media/images/favicon.png';
import { logOut } from '../utils/logout';

class ListProjectsWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.logOutOfPersonal = this.logOutOfPersonal.bind(this);
  }

  logOutOfPersonal() {
    logOut(this.props.history, window.location.pathname);
  }

  render() {
    const { isLoading, error, children } = this.props;
    const helmetProps = {
      title: "Marvel – The power of design in everyone's hands",
      meta: [
        { name: 'description', content: '' },
        { name: 'keywords', content: '' },
      ],
      link: [
        {
          name: 'link',
          rel: 'shortcut icon',
          type: 'image/png',
          href: favicon,
        },
      ],
    };
    return (
      <PageWrap>
        <Helmet {...helmetProps} />
        <Spacing type="padding" position="topBottom" size="s">
          <Align type={'right'}>
            <Paragraph>
              <Link type="light" onClick={this.logOutOfPersonal}>
                Log out
              </Link>
            </Paragraph>
          </Align>
          <InlineGrid size="s">
            {isLoading && <Loader size="large" color="blue" />}
          </InlineGrid>
        </Spacing>
        {error && <MessageBlock type="error">{error}</MessageBlock>}
        {children}
      </PageWrap>
    );
  }
}

ListProjectsWrapper.propTypes = {
  children: PropTypes.node,
  error: PropTypes.string,
  heading: PropTypes.string,
  isLoading: PropTypes.bool,
};

ListProjectsWrapper.defaultProps = {
  isLoading: false,
};

export default withRouter(ListProjectsWrapper);
