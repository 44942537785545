import React from 'react';

import { AuthTokenKey } from '../utils/config';
import ErrorState from './ErrorState';

class OauthTokenRetrieve extends React.Component {
  constructor(props) {
    super(props);
    window.microsoftTeams.initialize();
  }

  retrieveOauthToken() {
    const accessToken = window.location.href.match(
      /\#(?:access_token)\=([\S\s]*?)\&/
    );
    if (accessToken) {
      localStorage.setItem(AuthTokenKey, accessToken[1]);
      setTimeout(
        window.microsoftTeams.authentication.notifySuccess(accessToken[1]),
        1000
      );
    } else {
      return <ErrorState />;
    }

    //if (window.opener && window.opener.location) {
    //  window.opener.location.href = '/';
    //  window.close();
    //}
  }

  render() {
    return this.retrieveOauthToken();
  }
}

export default OauthTokenRetrieve;
