import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Route, Switch, withRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { GlobalStyle } from './globalStyles';

import '@marvelapp/marveloucss/marveloucss.css';
import '@marvelapp/marvel-ui/lib/styles.css';

import Signin from './components/Signin';
import Remove from './components/Remove';
import Help from './components/HelpLink';
import ErrorState from './components/ErrorState';
import ListPersonalProjects from './components/ListProjects';
import ListTabs from './components/ListTabs';
import ListCompanyProjects from './components/ListCompanyProjects';
import Player from './components/Player';
import OauthTokenRetrieve from './components/OauthTokenRetrieve';
import OauthTokenStart from './components/OauthTokenStart';
import ManageConfig from './components/ManageConfig';

import { AuthTokenKey, GraphqlEndpoint } from './utils/config';

class App extends Component {
  constructor(props) {
    super(props);
    // Get the Access Token from local storage
    const authToken = localStorage.getItem(AuthTokenKey);
    let tokenLength = 0;
    if (authToken) {
      tokenLength = authToken.length;
    }
    // Set the Graphql endpoint
    const httpLink = createHttpLink({
      uri: GraphqlEndpoint,
    });

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = authToken;
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : authToken,
        },
      };
    });

    const errorLink = onError(({ networkError, graphQLErrors }) => {
      if (graphQLErrors) {
        const oauth2Error = `OAuth2 token expired or invalid`;
        graphQLErrors.map(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
          this.props.history.push({
            pathname: '/signin',
            state: {
              redirect: window.location.pathname,
            },
          });
        });
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const link = ApolloLink.from([authLink, errorLink, httpLink]);

    this.client = new ApolloClient({
      link,
      cache: new InMemoryCache(),
    });
  }

  render() {
    return (
      <ApolloProvider client={this.client}>
        <Switch>
          {/* These routes are public */}
          <Route exact path="/help" component={Help} />
          <Route exact path="/config" component={ManageConfig} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/signin/:rootpage" component={Signin} />
          <Route exact path="/remove" component={Remove} />
          <Route exact path="/oauth/callback" component={OauthTokenRetrieve} />
          <Route
            exact
            path="/oauth/redirect/:type/"
            component={OauthTokenStart}
          />
          <Route exact path="/list/tabs/:type" component={ListTabs} />

          {/* These routes should check for autorisation */}
          <Route exact path="/" component={ListPersonalProjects} />
          <Route exact path="/personal" component={ListPersonalProjects} />
          <Route exact path="/company" component={ListCompanyProjects} />
          <Route exact path="/player/:vanityId/:type" component={Player} />
          <Route exact path="/error" component={ErrorState} />
        </Switch>
      </ApolloProvider>
    );
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(App);
