import React from 'react';
import { withRouter } from 'react-router-dom';

import Helmet from 'react-helmet';
import EmptyState from './EmptyState';
import Heading from '@marvelapp/marvel-ui/heading';
import Paragraph from '@marvelapp/marvel-ui/paragraph';
import Align from '@marvelapp/marvel-ui/align';
import Link from '@marvelapp/marvel-ui/link';
import PageWrap from '@marvelapp/marvel-ui/page-wrap';
import Spacing from '@marvelapp/marvel-ui/spacing';
import Grid, { GridItem } from '@marvelapp/marvel-ui/grid';
import Select from '@marvelapp/marvel-ui/select';
import { logOut } from '../utils/logout';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import favicon from '../media/images/favicon.png';

const UserQuery = gql`
  fragment image on ImageScreen {
    filename
    url
    height
    width
  }

  query user {
    user {
      username
      email
      projects(first: 1000) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            pk
            name
            prototypeUrl
            screens(first: 1) {
              edges {
                node {
                  name
                  modifiedAt
                  content {
                    __typename
                    ...image
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

class ManageConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultSet: false,
      projects: [],
      projectSelected: null,
      isIE: false,
    };

    this.logOutOfConfig = this.logOutOfConfig.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onProjectChange = this.onProjectChange.bind(this);
    this.handleMSConfiguration = this.handleMSConfiguration.bind(this);
    this.handleScriptLoad();
    if (
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      navigator.appVersion.indexOf('Trident/') > 0
    ) {
      this.state.isIE = true;
    }
  }

  handleScriptLoad() {
    window.microsoftTeams.initialize();
    window.microsoftTeams.settings.registerOnSaveHandler(
      this.handleMSConfiguration
    );
  }

  onChange() {
    window.microsoftTeams.settings.setValidityState(true);
  }

  onProjectChange(ev) {
    this.setState({ projectSelected: ev.target.value });
    window.microsoftTeams.settings.setValidityState(true);
    this.handleMSConfiguration();
  }

  handleMSConfiguration(saveEvent) {
    const projectSelected = this.state.projectSelected;
    let projectNode = null;
    for (var project of this.state.projects) {
      if (parseInt(project.node.pk, 10) === parseInt(projectSelected, 10)) {
        projectNode = project.node;
        break;
      }
    }
    console.log('Saving project as config', projectNode);
    let screenImage = null;
    if (
      projectNode.screens &&
      projectNode.screens.edges &&
      projectNode.screens.edges[0]
    ) {
      screenImage = projectNode.screens.edges[0].node.content.url;
      console.log('Found screen image to add ', screenImage);
    }

    window.microsoftTeams.settings.setSettings({
      entityId: `${projectNode.pk}`,
      contentUrl: `https://msoft.marvelapp.com/player/${
        projectNode.prototypeUrl.split('/')[4]
      }/tab/`,
      suggestedDisplayName: projectNode.name,
      websiteUrl: 'https://marvelapp.com/?image=' + screenImage,
      removeUrl: 'https:///msoft.marvelapp.com/remove/',
    });
    if (saveEvent) saveEvent.notifySuccess();
  }

  getProjectOptions() {
    const options = [];
    const projects = this.state.projects;
    console.log(projects);
    if (
      !this.state.defaultSet &&
      this.state.projects &&
      this.state.projects[0]
    ) {
      this.state.defaultSet = true;
      this.state.projectSelected = this.state.projects[0].node.pk;
      window.microsoftTeams.settings.setValidityState(true);
    }
    projects.map((project) => {
      options.push({
        value: project.node.pk,
        label: project.node.name,
      });
    });
    return options;
  }

  logOutOfConfig() {
    logOut(this.props.history, ' /config/');
  }

  render() {
    const isLoading = this.props.data.loading;
    var user = this.props.data.user;
    var projects = [];
    let options = [];
    if (user) {
      projects = user.projects.edges;
      this.state.projects = projects;
      options = this.getProjectOptions(projects);
    }

    let selectOptions = <div />;

    if ((!options || options.length === 0) && !isLoading) {
      selectOptions = (
        <div>
          <EmptyState type={'personal'} />
        </div>
      );
    } else if (!isLoading) {
      selectOptions = (
        <div>
          <Spacing type="margin" size="s" position="topBottom">
            <Heading tag={'h1'} size="l" weight={'regular'}>
              Choose a project to pin
            </Heading>
          </Spacing>
          <Grid columns={2}>
            <GridItem>
              {this.state.isIE ? (
                <select
                  placeholder={'Select a project'}
                  value={this.state.projectSelected}
                  size="m"
                  onChange={this.onProjectChange}
                >
                  {options.map((option, index) => {
                    return (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <Select
                  placeholder={'Select a project'}
                  searchable={true}
                  value={this.state.projectSelected}
                  options={options}
                  size="m"
                  onChange={this.onProjectChange}
                />
              )}
            </GridItem>
          </Grid>
        </div>
      );
    }

    return (
      <div>
        <Helmet
          title="Marvel – The power of design in everyone's hands"
          meta={[
            { name: 'description', content: 'Manage your team config' },
            { name: 'keywords', content: '' },
          ]}
          link={[
            {
              name: 'link',
              rel: 'shortcut icon',
              type: 'image/png',
              href: favicon,
            },
          ]}
        />
        <PageWrap size="l">
          <Spacing type="margin" size="s" position="topBottom">
            <Align type={'right'}>
              <Paragraph>
                <Link type="light" onClick={this.logOutOfConfig}>
                  Log out
                </Link>
              </Paragraph>
            </Align>
          </Spacing>
          {selectOptions}
        </PageWrap>
      </div>
    );
  }
}

export default withRouter(graphql(UserQuery)(ManageConfig));
