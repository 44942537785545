import { AuthTokenKey } from '../utils/config';

export function logOut(history, rootPage) {
  localStorage.removeItem(AuthTokenKey);
  history.push({
    pathname: '/signin',
    state: {
      redirect: rootPage,
    },
  });
}
