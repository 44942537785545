import React from 'react';

import '@marvelapp/marvel-ui/lib/styles.css';

import AspectRatio from '@marvelapp/marvel-ui/aspect-ratio';
import Heading from '@marvelapp/marvel-ui/heading';
import List from '@marvelapp/marvel-ui/list';
import Link from '@marvelapp/marvel-ui/link';
import Position from '@marvelapp/marvel-ui/position';
import Paragraph from '@marvelapp/marvel-ui/paragraph';
import Spacing from '@marvelapp/marvel-ui/spacing';

class HelpLink extends React.Component {
  render() {
    return (
      <div>
        <AspectRatio ratio="1-1">
          <Position type="absolute" location="topLeft">
            <Spacing type="padding" size="l">
              <div className="display-inlineBlock">
                <Spacing type="margin" size="m" position="bottom">
                  <Paragraph tag="span" size="m">
                    The Marvel Teams integration allows you to add and share
                    Marvel interactive wireframes, designs and prototypes to
                    channels inside in Teams in a few easy clicks.
                  </Paragraph>
                </Spacing>
                <Spacing type="margin" size="xs" position="topBottom">
                  <Heading weight="semibold" size="xl">
                    How to pin a project to a channel
                  </Heading>
                </Spacing>

                <Spacing type="margin" size="m" position="bottom">
                  <Paragraph tag="span" size="m">
                    <List>
                      <div className="whiteSpace-nowrap">
                        Once you have installed teams you can add your first
                        project to a channel using the + icon at the top of your
                        chat window
                      </div>
                      <div className="whiteSpace-nowrap">
                        Choose the Marvel tab icon on the "Add a tab" modal that
                        appears
                      </div>
                      <div className="whiteSpace-nowrap">
                        Choose a project from this dropdown list and click the
                        "Save" button.
                      </div>
                      <div className="whiteSpace-nowrap">
                        Once you have added a tab you will see the project play
                        and give you the option to interact and navigate your
                        prototype and designs.
                      </div>
                    </List>
                  </Paragraph>
                </Spacing>

                <Spacing type="margin" size="xs" position="bottom">
                  <Heading weight="semibold" size="xl">
                    How to create a project in Marvel
                  </Heading>
                </Spacing>

                <Spacing type="margin" size="m" position="bottom">
                  <Paragraph tag="span" size="m">
                    If you don't yet have a project to share within a channel
                    you can build your first prototype in Marvel using our{' '}
                    <Link
                      type="blue"
                      target={'_blank'}
                      url={
                        'https://help.marvelapp.com/hc/en-us/articles/360000150549-How-to-create-your-first-prototype'
                      }
                    >
                      getting started guide
                    </Link>
                  </Paragraph>
                </Spacing>
              </div>
            </Spacing>
          </Position>
        </AspectRatio>
      </div>
    );
  }
}

export default HelpLink;
