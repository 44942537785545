import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import ListProjectsWrapper from './ListProjectsWrapper';
import LoadMoreButton from './LoadMoreButton';
import ProjectGrid from './ProjectGrid';
import EmptyState from './EmptyState';

class ListProjects extends Component {
  getPrototypeUrl(prototypeUrl) {
    return `https://marvelapp.com/${prototypeUrl.split('/')[4]}/`;
  }

  mergeProjects(tabList) {
    let parsedProjects = [];
    tabList.map((tab) => {
      let screenUrl = null;
      if (tab.websiteUrl) {
        screenUrl = tab.websiteUrl.split('=')[1];
      }
      let project = {
        node: {
          screens: {
            edges: [
              {
                node: {
                  content: { url: screenUrl },
                },
              },
            ],
          },
          tab: tab,
          settings: {
            deviceFrame: 'IPHONE6',
          },
          modifiedAt: null,
          name: tab.tabName,
          prototypeUrl: this.getPrototypeUrl(tab.url),
        },
      };
      parsedProjects.push(project);
    });
    return parsedProjects;
  }

  render() {
    const { loading, error, user, loadMoreEntries } = this.props;
    let tabList = this.props.tabList;
    let tabsFetched = this.props.tabsFetched;
    if (tabsFetched == null) {
      tabsFetched = true;
    }

    console.log('Props are ', this.props);

    let heading = this.props.heading || 'My Projects';
    if (error) {
      return (
        <ListProjectsWrapper
          heading="My Projects"
          error="Something went wrong!"
        />
      );
    }

    if (loading) {
      return <ListProjectsWrapper heading={heading} isLoading />;
    }

    if (!user) {
      return <ListProjectsWrapper heading={heading} isLoading />;
    }

    const projects = user.projects;
    const hasMoreProjects = projects.pageInfo.hasNextPage;

    if (!projects || !projects.pageInfo) {
      return (
        <ListProjectsWrapper
          heading={heading}
          error="Error returning projects."
        />
      );
    }
    let projectElement = null;

    if ((!projects.edges || projects.edges.length === 0) && !loading) {
      let type = 'projectList';
      if (tabList && tabList.length === 0 && tabsFetched) {
        type = 'tab';
      }
      projectElement = <EmptyState type={type} />;
    } else if (tabList && !tabsFetched) {
      projectElement = <div>Fetching pinned tabs</div>;
    } else if (tabList && tabList.length === 0 && tabsFetched) {
      projectElement = <EmptyState type={'tab'} />;
    } else {
      let projectList = projects.edges;
      if (tabList) {
        projectList = this.mergeProjects(tabList);
      }
      projectElement = <ProjectGrid projects={projectList} tabList={tabList} />;
    }

    return (
      <ListProjectsWrapper heading={heading}>
        {projectElement}
        {!tabList && hasMoreProjects && (
          <LoadMoreButton onClick={loadMoreEntries} />
        )}
      </ListProjectsWrapper>
    );
  }
}

ListProjects.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  loadMoreEntries: PropTypes.func,
  user: PropTypes.object,
};

const ProjectQuery = gql`
  fragment image on ImageScreen {
    filename
    url
    height
    width
  }

  query user($cursor: String) {
    user {
      username
      email
      projects(first: 50, after: $cursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            pk
            name
            prototypeUrl
            modifiedAt
            settings {
              deviceFrame
            }
            screens(first: 1) {
              edges {
                node {
                  name
                  modifiedAt
                  content {
                    __typename
                    ...image
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ProjectsWithData = graphql(ProjectQuery, {
  // This function re-runs every time `data` changes, including after `updateQuery`,
  // meaning our loadMoreEntries function will always have the right cursor
  props({ data: { loading, user, fetchMore } }) {
    return {
      loading,
      user,
      loadMoreEntries: () => {
        return fetchMore({
          query: ProjectQuery,
          variables: {
            cursor: user.projects.pageInfo.endCursor,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = fetchMoreResult.user.projects.edges;
            fetchMoreResult.user.projects.edges = [
              ...previousResult.user.projects.edges,
              ...newEdges,
            ];
            return {
              user: fetchMoreResult.user,
            };
          },
        });
      },
    };
  },
})(ListProjects);

export default ProjectsWithData;
