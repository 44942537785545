import React from 'react';
import { withRouter } from 'react-router-dom';

import Spacing from '@marvelapp/marvel-ui/spacing';
import Button from '@marvelapp/marvel-ui/button';
import Heading from '@marvelapp/marvel-ui/heading';
import Paragraph from '@marvelapp/marvel-ui/paragraph';
import Align from '@marvelapp/marvel-ui/align';
import { InlineGrid } from '@marvelapp/marvel-ui/grid';
import MaxWidth from '@marvelapp/marvel-ui/max-width';
import { AuthTokenKey } from '../utils/config';

class Signin extends React.Component {
  constructor(props) {
    super(props);
    window.microsoftTeams.initialize();
    this.rootpage = null;
    if (this.props.location && this.props.location.state) {
      this.rootpage = this.props.location.state.redirect;
    }

    if (
      this.rootpage == null ||
      this.rootpage === '' ||
      this.rootpage.indexOf('signin') > -1
    ) {
      this.rootpage = '/config/';
    }

    window.rootpage = this.rootpage;
    this.signin = this.signin.bind(this);
    this.signup = this.signup.bind(this);
  }

  signin() {
    const oauthStartPoint = `/oauth/redirect/signin/`;
    window.microsoftTeams.authentication.authenticate({
      url: oauthStartPoint,
      width: 640,
      height: 800,
      successCallback: function (result) {
        console.log(
          'The signin root page is: ' + result + ' ',
          window.rootpage
        );
        localStorage.setItem(AuthTokenKey, result);
        window.location.href = window.rootpage;
      },
      failureCallback: (reason) => {
        this.props.history.push({
          pathname: '/signin',
          state: {
            redirect: window.rootpage,
          },
        });
      },
    });
  }

  signup() {
    const oauthStartPoint = `/oauth/redirect/signup/`;
    window.microsoftTeams.authentication.authenticate({
      url: oauthStartPoint,
      width: 640,
      height: 800,
      successCallback: function (result) {
        console.log(
          'The signup root page is: ' + result + ' ',
          window.rootpage
        );
        localStorage.setItem(AuthTokenKey, result);
        window.location.href = window.rootpage;
      },
      failureCallback: (reason) => {
        this.props.history.push({
          pathname: '/signup',
          state: {
            redirect: window.rootpage,
          },
        });
      },
    });
  }

  render() {
    return (
      <div>
        <Spacing type="padding" size="l" position="top">
          <Spacing type="padding" size="l" position="bottom">
            <Align type="center">
              <MaxWidth value={640}>
                <Spacing type="margin" size="xs" position="bottom">
                  <Heading tag="h2" weight="semibold" size="xl">
                    Sign in or Sign up to use Marvel
                  </Heading>
                </Spacing>
                <Paragraph size="m" color="slate">
                  Marvel is free to use for as long as you want with two active
                  projects.
                </Paragraph>
                <Spacing type="margin" size="l">
                  <InlineGrid columns={2}>
                    <Button
                      background="green"
                      size="medium"
                      onClick={this.signin}
                    >
                      Sign In
                    </Button>
                    <Button
                      background="blue"
                      size="medium"
                      onClick={this.signup}
                    >
                      Sign Up
                    </Button>
                  </InlineGrid>
                </Spacing>
              </MaxWidth>
            </Align>
          </Spacing>
        </Spacing>
      </div>
    );
  }
}

export default withRouter(Signin);
