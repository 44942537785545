import React from 'react';
import PropTypes from 'prop-types';

import '@marvelapp/marvel-ui/lib/styles.css';

import Align from '@marvelapp/marvel-ui/align';
import AspectRatio from '@marvelapp/marvel-ui/aspect-ratio';
import Heading from '@marvelapp/marvel-ui/heading';
import Position from '@marvelapp/marvel-ui/position';
import Spacing from '@marvelapp/marvel-ui/spacing';

class ErrorState extends React.Component {
  render() {
    return (
      <Align type={'center'}>
        <AspectRatio ratio="16-9">
          <Position type="relative" location="center">
            <div className="display-inlineBlock">
              <Spacing type="padding" size="m" />
              <div>
                <Heading weight="semibold" size="m">
                  There was an error connecting to the Marvel API
                </Heading>
                <Spacing type="padding" size="s" />
              </div>
              <Spacing type="padding" size="s" />
            </div>
          </Position>
        </AspectRatio>
      </Align>
    );
  }
}

ErrorState.propTypes = {
  type: PropTypes.string,
};

export default ErrorState;
