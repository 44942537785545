import React from 'react';

import Helmet from 'react-helmet';

import ListProjects from './ListProjects';

import favicon from '../media/images/favicon.png';

const RECENT = 'Recent';
const ALL = 'All';

class ListTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [],
      tabsFetched: false,
    };
    let type = this.props.match.params.type;
    if (type === 'recent') {
      type = RECENT;
    } else if (type === 'all') {
      type = ALL;
    }
    this.state.tabType = type || RECENT;
    this.handleScriptLoad();
    this.loadTabs = this.loadTabs.bind(this);
  }

  componentDidMount() {
    console.log('Calling component did mount and getting tabs');
    this.getTabs();
  }

  handleScriptLoad() {
    window.microsoftTeams.initialize();
  }

  getTabs() {
    if (this.state.tabType === RECENT) {
      console.log('Calling get recent tabs');
      window.microsoftTeams.getMruTabInstances(this.loadTabs);
    } else {
      console.log('Calling get all tabs');
      window.microsoftTeams.getTabInstances(this.loadTabs);
    }
  }

  loadTabs(tabInstance) {
    console.log('The tab instance returned by teams is ', tabInstance);
    const tabs = tabInstance.teamTabs;
    console.log('The tabs are ', tabs);
    this.setState({ tabs: tabs, tabsFetched: true });
  }

  render() {
    const tabs = this.state.tabs || [];
    const tabType = this.state.tabType || '';
    return (
      <div>
        <Helmet
          title="Marvel – The power of design in everyone's hands"
          meta={[
            { name: 'description', content: 'List of your tabs' },
            { name: 'keywords', content: '' },
          ]}
          link={[
            {
              name: 'link',
              rel: 'shortcut icon',
              type: 'image/png',
              href: favicon,
            },
          ]}
        />
        <ListProjects
          heading={`${tabType} Tabs`}
          tabList={tabs}
          tabsFetched={this.state.tabsFetched}
        />
      </div>
    );
  }
}

export default ListTabs;
