import { createGlobalStyle } from 'styled-components';
import { color, typography } from 'styled-system';

export const GlobalStyle = createGlobalStyle`
  button, html input[type="button"], input[type="reset"], input[type="submit"] {
    line-height: 1 !important;
  }

  body {
    ${color};
    ${typography};
    background-color: transparent !important;
    letter-spacing: -0.2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  // Hides hubspot cookie banner inside the product
  #hs-eu-cookie-confirmation {
    display: none;
  }

  html {
    background-color: transparent !important;
  }

  a, a:link, a:visited {
    text-decoration: none;
  }

  .textarea {
    ::-webkit-input-placeholder {
      padding-top: 4px;
    }
  }
`;
