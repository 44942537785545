import React from 'react';
import PropTypes from 'prop-types';

import '@marvelapp/marvel-ui/lib/styles.css';
import happyFolder from '../media/images/happy-folder@1x.png';
import happyFolder2x from '../media/images/happy-folder@2x.png';

import Align from '@marvelapp/marvel-ui/align';
import AspectRatio from '@marvelapp/marvel-ui/aspect-ratio';
import Button from '@marvelapp/marvel-ui/button';
import Heading from '@marvelapp/marvel-ui/heading';
import Position from '@marvelapp/marvel-ui/position';
import Spacing from '@marvelapp/marvel-ui/spacing';

class EmptyState extends React.Component {
  render() {
    let emptyStateMessage = (
      <div>
        <Heading weight="semibold" size="m">
          No projects created in Marvel yet
        </Heading>
        <Spacing type="padding" size="s" />
        <a href={'https://marvelapp.com/dashboard/'} target={'_blank'}>
          <Button size="medium" shape={'rounded'}>
            Create your first project
          </Button>
        </a>
      </div>
    );
    if (this.props.type === 'tab') {
      emptyStateMessage = (
        <div>
          <Heading weight="semibold" size="m">
            There are no projects pinned to a channel yet
          </Heading>
          <Spacing type="padding" size="s" />
        </div>
      );
    }
    return (
      <Align type={'center'}>
        <AspectRatio ratio="16-9">
          <Position type="relative" location="center">
            <div className="display-inlineBlock">
              <div>
                <img
                  src={happyFolder}
                  srcSet={`${happyFolder} 1x, ${happyFolder2x} 2x`}
                />
              </div>
              <Spacing type="padding" size="m" />
              {emptyStateMessage}
              <Spacing type="padding" size="s" />
            </div>
          </Position>
        </AspectRatio>
      </Align>
    );
  }
}

EmptyState.propTypes = {
  type: PropTypes.string,
};

export default EmptyState;
