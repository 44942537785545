import React from 'react';
import PropTypes from 'prop-types';

class OauthTokenStart extends React.Component {
  redirectToOauth(type) {
    const oauthRedirect =
      'https://marvelapp.com/oauth/authorize/?client_id=YbSWlrvsxuf5B0uez7VbCZ8kPofDUxTuKnCh2hr8&response_type=token&scope=user:read%20projects:read%20company:read';
    let signupUrl =
      'https://marvelapp.com/signup?next=' + encodeURI(oauthRedirect);
    signupUrl = signupUrl.split('&').join('%26');

    if (type === 'signup') {
      window.location.href = signupUrl;
    } else {
      window.location.href = oauthRedirect;
    }
  }

  render() {
    const type = this.props.match.params.type;
    this.redirectToOauth(type);
    return null;
  }
}

OauthTokenStart.propTypes = {
  match: PropTypes.object,
};

export default OauthTokenStart;
