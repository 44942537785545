import React from 'react';
import PropTypes from 'prop-types';

import Align from '@marvelapp/marvel-ui/align';
import Button from '@marvelapp/marvel-ui/button';
import Spacing from '@marvelapp/marvel-ui/spacing';

export default function LoadMoreButton({ onClick }) {
  return (
    <Spacing type="padding" position="topBottom" size="l">
      <Align type="center">
        <Button onClick={onClick}>Load More</Button>
      </Align>
    </Spacing>
  );
}

LoadMoreButton.propTypes = {
  onClick: PropTypes.func,
};
