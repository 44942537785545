import React from 'react';
import PropTypes from 'prop-types';

import '@marvelapp/marvel-ui/lib/styles.css';

import Position from '@marvelapp/marvel-ui/position';
import Spacing from '@marvelapp/marvel-ui/spacing';
import { ToolbarButton } from '@marvelapp/marvel-ui/toolbar';
import createPrototypeViewer from '@marvelapp/prototype-viewer';
import './detect-element-resize';

class Player extends React.Component {
  goBack() {
    window.history.back();
  }

  render() {
    const PrototypeViewer = createPrototypeViewer(
      {},
      {
        areKeyboardControlsEnabled: false,
        userIsAuthenticated: true,
        userIsInHandoffTestGroup: true,
        userIsSuperUser: true,
        isDownloaded: true,
        isAppBuild: true,
        plan: 'Pro',
        userRole: 'Editor',
        isNavigationEnabled: false,
      }
    );
    PrototypeViewer.loadProject(this.props.match.params.vanityId);
    const tabType = this.props.match.params.type;
    console.log("the props match?", this.props.match.params);
    let goBackButton = (
      <ToolbarButton onClick={this.goBack}>Go back</ToolbarButton>
    );
    if (tabType === 'tab') {
      goBackButton = null;
    }
    return (
      <div className="height-100">
        <PrototypeViewer />
        <Position type="absolute" location="topLeft">
          <Spacing type="padding" size="xs">
            {goBackButton}
          </Spacing>
        </Position>
      </div>
    );
  }
}

Player.propTypes = {
  match: PropTypes.object,
};

export default Player;
