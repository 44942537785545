import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import ListProjectsWrapper from './ListProjectsWrapper';
import LoadMoreButton from './LoadMoreButton';
import ProjectGrid from './ProjectGrid';

function ListCompanyProjects({ loading, error, user, loadMoreEntries }) {
  if (loading) {
    return <ListProjectsWrapper heading="My Company" isLoading />;
  }

  if (error) {
    return (
      <ListProjectsWrapper heading="My Company" error="Something went wrong!" />
    );
  }

  if (!user) {
    return <ListProjectsWrapper heading="My Company" error="No user found." />;
  }

  if (!user.company) {
    return (
      <ListProjectsWrapper heading="My Company" error="No company found." />
    );
  }

  const projects = user.projects;
  const hasMoreProjects = projects.pageInfo.hasNextPage;

  return (
    <ListProjectsWrapper heading="My Projects">
      <ProjectGrid projects={projects.edges} />
      {hasMoreProjects && <LoadMoreButton onClick={loadMoreEntries} />}
    </ListProjectsWrapper>
  );
}

ListCompanyProjects.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  loadMoreEntries: PropTypes.func,
  user: PropTypes.object,
};

const ProjectQuery = gql`
  query user {
    user {
      username
      email
      company {
        projects(first: 10) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              pk
              name
              prototypeUrl
              screens(first: 1) {
                edges {
                  node {
                    name
                    modifiedAt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const CompanyProjectsWithData = graphql(ProjectQuery, {
  // This function re-runs every time `data` changes, including after `updateQuery`,
  // meaning our loadMoreEntries function will always have the right cursor
  props({ data: { loading, cursor, user, fetchMore } }) {
    return {
      loading,
      user,
      loadMoreEntries: () => {
        return fetchMore({
          query: ProjectQuery,
          variables: {
            cursor: cursor,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const previousEntry = previousResult.entry;
            const newProjects = fetchMoreResult.user.company.projects;
            const newCursor = fetchMoreResult.user.company.projects.cursor;

            return {
              // By returning `cursor` here, we update the `loadMore` function
              // to the new cursor.
              cursor: newCursor,

              entry: {
                // Put the new projects in the front of the list
                projects: [
                  ...newProjects,
                  ...previousEntry.user.company.projects,
                ],
              },
            };
          },
        });
      },
    };
  },
})(ListCompanyProjects);

export default CompanyProjectsWithData;
