import React from 'react';

class Remove extends React.Component {
  removeTab() {
    window.microsoftTeams.initialize();
    window.microsoftTeams.settings.setValidityState(true);
  }

  render() {
    this.removeTab();
    return <div />;
  }
}

export default Remove;
